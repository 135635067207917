<template>
  <b-row>
    <b-col cols="12">
      <b-card class="my-2" title="Roles" v-if="showRoles">
        <b-row v-if="rolesLoading">
          <b-col cols="12" class="mb-2 text-center">
            <b-spinner variant="secondary"></b-spinner>
          </b-col>
        </b-row>
        <b-row v-if="!rolesLoading && rolesData && rolesData.length">
          <b-col cols="12" md="6" class="mb-2" :key="role.id" v-for="role in rolesData">
            <b-form-checkbox :value="role.id" v-model="userRoles">{{ role.name }}</b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-2" title="Permissions">
        <b-row>
          <b-col cols="12" md="6" class="mb-2" :key="permission.apiname" v-for="permission in allPermissions">
            <b-form-checkbox :value="permission.apiname" v-model="userPermissions">{{ permission.label }}</b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import flatMap from 'lodash/flatMap'
import allPermissions from '../permissions.json'
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('users', ['rolesData', 'rolesLoading'])
  },
  methods: {
    ...mapActions('users', ['fetchRoles'])
  },
  created () {
    this.fetchRoles()
  },
  data () {
    return {
      allPermissions,
      userRoles: typeof this.roles[0] === 'object' ? flatMap(this.roles, 'id') : this.roles,
      userPermissions: typeof this.permissions[0] === 'object' ? flatMap(this.permissions, 'name') : this.permissions
    }
  },
  props: {
    permissions: {
      default: () => [],
      type: Array
    },
    showRoles: {
      default: false,
      type: Boolean
    },
    roles: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    userRoles (value) {
      this.$emit('update', {
        permissions: this.userPermissions,
        roles: this.userRoles
      })
    },
    userPermissions (value) {
      this.$emit('update', {
        permissions: this.userPermissions,
        roles: this.userRoles
      })
    }
  }
}
</script>

<style>

</style>
