<template>
  <router-view />
</template>

<script>
import userModule from '../../store/users'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('users')) {
      this.$store.registerModule('users', userModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('users')) {
      this.$store.unregisterModule('users')
    }
  }
}
</script>
