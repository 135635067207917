<template>
  <b-modal :id="id" size="md" title="Add/Update Deduction"
    ok-title="Submit" ok-variant="secondary" @ok="submit"
    cancel-title="Close" cancel-variant="primary" :ok-disabled="value === 0">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Reason for Deduction">
          <b-form-input type="text" v-model="reason" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Deduction Amount">
          <b-input-group prepend="£">
            <b-form-input type="text" v-model="deduction" />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Applied At">
          <b-datepicker v-model="appliedAt" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import currentUser from '../../mixins/currentUser'
import { mapState } from 'vuex'

const initialData = () => ({
  reason: '',
  deduction: 0,
  appliedAt: undefined
})

export default {
  name: 'DeductionsModal',
  mixins: [currentUser],
  props: {
    id: String,
    value: Object
  },
  data () {
    return initialData()
  },
  computed: mapState('authentication', ['current']),
  methods: {
    submit () {
      this.$emit('update', {
        id: this.value && this.value.id,
        reason: this.reason,
        value: this.deduction,
        applied_at: this.appliedAt
      })
      this.reason = ''
      this.deduction = 0
      this.appliedAt = undefined
    }
  },
  watch: {
    'value' (value) {
      if (value) {
        this.reason = value.reason
        this.deduction = value.value
        this.appliedAt = value.applied_at
      } else {
        Object.assign(this.$data, initialData())
      }
    }
  }
}
</script>
