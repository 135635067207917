<template>
  <b-table bordered responsive striped head-variant="dark" :fields="fields" :items="items">
    <template #cell(_)="{ index }">
      <b-button block @click="$emit('edit', index)" variant="primary">Edit</b-button>
    </template>
  </b-table>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DeductionsTable',
  data () {
    return {
      fields: [
        { key: 'reason', label: 'Reason', tdClass: 'align-middle' },
        { key: 'value', label: 'Deduction Amount', tdClass: 'align-middle' },
        { key: 'applied_at', label: 'Applied At', tdClass: 'align-middle' },
        { key: '_', text: '' }
      ]
    }
  },
  props: {
    value: Array
  },
  computed: {
    items () {
      return this.value.map(deal => ({
        reason: deal.reason,
        applied_at: moment(deal.applied_at || deal.created_at).format('DD/MM/YYYY'),
        value: new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(deal.value)
      }))
    }
  }
}
</script>
