<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} User</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Forename" :invalid-feedback="validationInvalidFeedback(errors, 'forename')" :state="validationState(errors, 'forename')">
                <b-form-input :disabled="isSaving" v-model="forename" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Surname" :invalid-feedback="validationInvalidFeedback(errors, 'surname')" :state="validationState(errors, 'surname')">
                <b-form-input :disabled="isSaving" v-model="surname" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Email Address" :invalid-feedback="validationInvalidFeedback(errors, 'email')" :state="validationState(errors, 'email')">
                <b-form-input :disabled="isSaving" type="email" v-model="email" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Confirm Email Address" :invalid-feedback="validationInvalidFeedback(errors, 'email_confirmation')" :state="validationState(errors, 'email_confirmation')">
                <b-form-input :disabled="isSaving" type="email" v-model="email_confirmation" />
              </b-form-group>
            </b-col>
            <b-col class="d-flex flex-row align-items-center" cols="12" md="4">
              <b-card class="w-100">
                <b-form-checkbox :disabled="isSaving" v-model="verified" value="1">Verified Email Address</b-form-checkbox>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Password" :invalid-feedback="validationInvalidFeedback(errors, 'password')" :state="validationState(errors, 'password')">
                <template #label>
                  Password <b-icon class="text-secondary" icon="info-square-fill" title="Password Strength Requirements" v-b-popover.hover.righttop="'A password must be a strong password. Please enter at least 8 characters, with at least one number and one uppercase character.'"></b-icon>
                </template>
                <b-form-input :disabled="isSaving" type="password" v-model="password" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Confirm Password" :invalid-feedback="validationInvalidFeedback(errors, 'password_confirmation')" :state="validationState(errors, 'password_confirmation')">
                <b-form-input :disabled="isSaving" type="password" v-model="password_confirmation" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="6">
              <b-form-group label="IP (Restrict Access)" :invalid-feedback="validationInvalidFeedback(errors, 'ip')" :state="validationState(errors, 'ip')">
                <b-form-input :disabled="isSaving" type="text" v-model="ip" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Commission Percentage (%)" :invalid-feedback="validationInvalidFeedback(errors, 'comms_perc')" :state="validationState(errors, 'comms_perc')">
                <b-input-group append="%">
                  <b-form-input :disabled="isSaving" type="number" v-model.number="comms_perc" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" lg="8">
              <h2>Deductions</h2>
            </b-col>
            <b-col cols="12" lg="4">
              <b-button block variant="secondary" v-b-modal.create-deductions-modal>Add Deduction</b-button>
            </b-col>
            <b-col cols="12">
              <deductions-modal @update="deductionUpdate" id="create-deductions-modal" :value="selectedDeduction" />
              <deductions-table @edit="deductionEdit" :value="deductions" />
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <h2>Roles &amp; Permissions</h2>
            </b-col>
            <b-col cols="12">
              <select-permissions @update="onPermissionsChange" show-roles :roles="roles" :permissions="permissions"></select-permissions>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cold="12" offset-md="8" md="4">
              <b-button block type="submit" :disabled="isSaving" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import flatMap from 'lodash/flatMap'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'
import SelectPermissions from '../../components/SelectPermissions.vue'
import DeductionsModal from './DeductionsModal.vue'
import DeductionsTable from './DeductionsTable.vue'

const initialData = () => ({
  ip: null,
  forename: null,
  surname: null,
  email: null,
  password: null,
  verified: false,
  comms_perc: 0,
  email_confirmation: null,
  password_confirmation: null,
  permissions: [],
  deductions: [],
  roles: [],

  selectedDeduction: null
})

export default {
  mixins: [validation],
  created () {
    if (this.$route.params.id) {
      this.fetchUser(this.$route.params.id)
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (_0, _1, next) {
    this.$store.dispatch('users/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  components: { DeductionsModal, DeductionsTable, SelectPermissions },
  computed: {
    ...mapGetters('users', ['errors', 'isLoading', 'isSaving', 'single'])
  },
  methods: {
    ...mapActions('users', ['fetchUser', 'saveUser']),
    onPermissionsChange ({ permissions, roles }) {
      this.permissions = permissions
      this.roles = roles
    },
    deductionEdit (index) {
      this.selectedDeduction = this.deductions[index]
      this.$bvModal.show('create-deductions-modal')
    },
    deductionUpdate (deduction) {
      if (deduction.id) {
        const found = this.deductions.find(predicate => deduction.id === predicate.id)
        this.deductions.splice(this.deductions.indexOf(found), 1, deduction)
      } else {
        this.deductions.push(deduction)
      }

      this.selectedDeduction = {}
      this.$bvModal.hide('create-deductions-modal')
    },
    submit () {
      let data = {
        id: this.$route.params.id,
        ip: this.ip,
        forename: this.forename,
        surname: this.surname,
        verified: this.verified,
        password: this.password,
        comms_perc: this.comms_perc,
        password_confirmation: this.password_confirmation,
        permissions: this.permissions,
        deductions: this.deductions,
        roles: this.roles
      }

      if (this.email !== this.single.email) {
        data = {
          ...data,
          email: this.email,
          email_confirmation: this.email_confirmation
        }
      }

      this.saveUser(Object.fromEntries(Object.entries(data).filter(([key, value]) => value !== null))).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `User ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This user has successfully been saved.'
        })

        if (!this.$route.params.id) {
          this.$router.push({ name: 'admin.users.update', params: { id: response.data.id } })
          return this.fetchUser(response.data.id)
        }

        return this.fetchUser(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} User`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    single (value) {
      this.ip = value.ip
      this.forename = value.forename
      this.surname = value.surname
      this.email = value.email
      this.verified = value.email_verified_at !== null
      this.comms_perc = value.comms_perc
      this.deductions = value.deductions || []
      this.permissions = flatMap(value.permissions, 'name')
      this.roles = flatMap(value.roles, 'id')
    }
  }
}
</script>
